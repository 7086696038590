import { sendSms, login, userInfo } from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
var state = {
  token: getToken(),
  name: '',
  avatar: ''
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  }
};
var actions = {
  // send sms
  action_sendSms: function action_sendSms(_ref, data) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      sendSms(data).then(function (response) {
        if (response.code === '7001001') {
          resolve(response.data);
        } else {
          resolve();
        }
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user login
  action_login: function action_login(_ref2, data) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      login(data).then(function (response) {
        var access_token = response.data.access_token;
        commit('SET_TOKEN', access_token);
        setToken(access_token);
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user info
  action_userInfo: function action_userInfo(_ref3) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      userInfo().then(function (response) {
        var data = response.data;

        if (!data) {
          reject('Verification failed, please Login again.');
        }

        var nickName = data.nickName,
            userAccount = data.userAccount,
            headImgUrl = data.headImgUrl;
        commit('SET_NAME', nickName || userAccount);
        commit('SET_AVATAR', headImgUrl);
        resolve(data);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  action_logout: function action_logout(_ref4) {
    var commit = _ref4.commit,
        dispatch = _ref4.dispatch;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      removeToken(); // must remove  token  first

      resetRouter(); // reset visited views and cached views
      // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485

      dispatch('tagsView/delAllViews', null, {
        root: true
      });
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};