/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}
/**
 * 验证是否是合法的用户名(用于rules验证)
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @returns
 */

export function isValidUserAccount(rule, value, callback) {
  var re = /^[a-zA-Z][a-zA-Z0-9_@]{4,30}\w$/;
  var checkRes = re.test(value);

  if (checkRes) {
    callback();
  } else {
    callback(new Error('用户登陆名必须由6-32位字母、数字、或特殊字符_@组成(须以字母开头且不能以@结尾)'));
  }
}
/**
 * 验证是否是合法的用户密码(用于rules验证)
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */

export function isValidUserPassword(rule, value, callback) {
  var re = /^(?![0-9]+$)(?![a-zA-Z]+$)(?![_]+$)(?![!]+$)(?![@]+$)(?![#]+$)(?![\$]+$)(?![%]+$)(?![\^]+$)(?![&]+$)(?![\*]+$)[a-zA-Z0-9_!@#\$%\^&\*]{8,16}$/;
  var checkRes = re.test(value);

  if (checkRes) {
    callback();
  } else {
    callback(new Error('用户密码必须由长度为8-16位的字母、数字以及特殊字符_!@#$%^&*中至少两种组成'));
  }
}
/**
 * 验证是否是合法的邮箱(用于rules验证)
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */

export function isValidEmail(rule, value, callback) {
  if (!value) {
    callback();
  } else {
    var re = /^[A-Za-zd0-9]+([-_.][A-Za-zd]+)*@([A-Za-zd0-9]+[-.])+[A-Za-zd]{2,5}$/;
    var checkRes = re.test(value);

    if (checkRes) {
      callback();
    } else {
      callback(new Error('非法的邮箱格式'));
    }
  }
}
/**
 * 验证是否是合法的手机号码(用于rules验证)
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */

export function isValidPhone(rule, value, callback) {
  var re = /^(\+86)?1[3-9]\d{9}$/;
  var checkRes = re.test(value);

  if (checkRes) {
    callback();
  } else {
    callback(new Error('请输入正确的手机号'));
  }
}
/**
 * 验证是否是合法的验证码(用于rules验证)
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */

export function isValidShortCode(rule, value, callback) {
  var re = /^[0-9]{6}$/;
  var checkRes = re.test(value);

  if (checkRes) {
    callback();
  } else {
    callback(new Error('验证码为6位数字'));
  }
}