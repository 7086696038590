import "/home/jenkins/workspace/job_b20f4962-44ca-44ae-b056-a92295dac7c5/node_modules/core-js/modules/es6.array.iterator.js";
import "/home/jenkins/workspace/job_b20f4962-44ca-44ae-b056-a92295dac7c5/node_modules/core-js/modules/es6.promise.js";
import "/home/jenkins/workspace/job_b20f4962-44ca-44ae-b056-a92295dac7c5/node_modules/core-js/modules/es6.object.assign.js";
import "/home/jenkins/workspace/job_b20f4962-44ca-44ae-b056-a92295dac7c5/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import 'normalize.css/normalize.css'; // A modern alternative to CSS resets

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import '@/styles/index.scss'; // global css

import App from "./App";
import store from "./store";
import router from "./router";
import '@/icons'; // icon

import '@/permission'; // permission control
// set ElementUI lang to EN

Vue.use(ElementUI, {
  locale: locale
}); // 如果想要中文版 element-ui，按如下方式声明
// Vue.use(ElementUI)
// 复制道剪贴板

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});