import request from '@/utils/request';
export function login(data) {
  return request({
    url: '/token/phoneSms',
    method: 'post',
    data: data
  });
}
export function getBehaviorImage(type) {
  return request({
    url: "/captcha/behaviorImage/get?type=".concat(type),
    method: 'get'
  });
}
export function checkBehaviorImage(data) {
  return request({
    url: '/captcha/behaviorImage/check',
    method: 'post',
    data: data
  });
}
export function sendSms(data) {
  return request({
    url: '/captcha/sms/send/afterVerify',
    method: 'post',
    data: data
  });
}
export function userInfo() {
  return request({
    url: '/userInfo/getInfo',
    method: 'get'
  });
}
export function userInfoPage(data) {
  return request({
    url: '/userInfo/admin/page',
    method: 'post',
    data: data
  });
}
export function delUser(id) {
  return request({
    url: "/userInfo/admin/deleteById/".concat(id),
    method: 'delete'
  });
}
export function recoverUser(id) {
  return request({
    url: "/userInfo/admin/recoverById/".concat(id),
    method: 'post'
  });
}
export function updateUser(data) {
  return request({
    url: '/userInfo/admin/update',
    method: 'put',
    data: data
  });
}