import _objectSpread from "/home/jenkins/workspace/job_b20f4962-44ca-44ae-b056-a92295dac7c5/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb';
import Hamburger from '@/components/Hamburger';
export default {
  components: {
    Breadcrumb: Breadcrumb,
    Hamburger: Hamburger
  },
  computed: _objectSpread({}, mapGetters(['sidebar', 'avatar'])),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    logout: function logout() {
      this.$store.dispatch('user/action_logout').then(function () {
        location.reload();
      });
    }
  }
};